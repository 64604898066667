.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.App-header {
  background: linear-gradient(135deg, #6e8efb, #a777e3);
  padding: 20px 0;
  color: white;
}

.App-logo {
  height: 80px;
  margin-bottom: 20px;
}

/* Ensure the content sections flexibly fill the available space */
.App-about, .App-links {
  flex: 1;
  padding: 20px;
  overflow-y: auto; /* Allows scrolling within each section if needed */
}

/* Additional CSS here for styling specific sections, responsive design, etc. */

.buy-button {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #4CAF50; /* Example button color */
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.buy-button:hover {
  background-color: #45a049;
}

.social-link {
  display: inline-block;
  margin: 10px;
  color: #1DA1F2; /* Twitter-like color for example */
  text-decoration: none;
}

.social-link:hover {
  text-decoration: underline;
}

table {
  width: 100%;
  margin: 20px auto;
  border-radius: collapse;
}

table, th, td {
  border: 1px solid #757474;
}

th, td {
  padding: 10px;
  text-align: left;
}

th {
  background-color: #2ED57380;
}
